import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
      "mainImage",
      "optionGroup",
      "productPrice",
      "variantId"
  ]

  static values = {
    id: String, imageUrl: String, option: Number, group: String
  }
  connect() {

  }

    changeImage(event) {
        this.mainImageTarget.src = event.currentTarget.src
    }

    async selectOptions(event) {
        var i
        var parameters = []
        for (i = 0; i < this.optionGroupTargets.length; i++) {
            parameters.push('optionGroup[' + this.optionGroupTargets[i].dataset.group + ']=' + this.optionGroupTargets[i].value)
        }
        // this.productPriceTarget.innerHTML = 'product_id=' + this.idValue + '&' + parameters.join('&')

        const response = await fetch(`/variants/?product_id=${this.idValue}&` + parameters.join('&'), { headers: { accept: "application/json" } })
        const data = await response.json()
        // Check if response is empty
        if (data == null) {
            showElement('notAvailableText')
            hideElement('productPriceView')
        } else {
            hideElement('notAvailableText')
            showElement('productPriceView')
            this.productPriceTarget.innerHTML = '$' + Number(data.price).toFixed(2)
            this.mainImageTarget.src = data.image_url
            this.variantIdTarget.value = data.id
        }

    }
}
