// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers/shop"
import * as bootstrap from "bootstrap"

// Show/hide an element by toggling the 'd-none' class
function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
}
window.toggleElement = toggleElement;

function showElement(element) {
    document.getElementById(element).classList.remove('d-none');
}
window.showElement = showElement;

function hideElement(element) {
    document.getElementById(element).classList.add('d-none');
}
window.hideElement = hideElement;

function submitCheckoutForm() {
    var validate_checkout = document.getElementById('validate_checkout').value;
    if (validate_checkout === 'none') {
        document.getElementById('validate_checkout').value = 'true';
        document.getElementById('checkoutButton').disabled = true;
        document.getElementById('theSpinner').style.display = 'block';
        document.getElementById('checkoutButton').innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait...';
        document.getElementById('new_transaction').submit();
        console.log('##### Form submitted #####');
    } else {
        console.log('##### Form was already submitted so we are not taking any action #####');
    }
}
window.submitCheckoutForm = submitCheckoutForm;

window.addEventListener("DOMContentLoaded", function() {
    const spinnerTriggers = document.querySelectorAll('.spinner_onclick');
    spinnerTriggers.forEach(el => el.addEventListener('click', event => {
        // disable element
        el.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait...';
        el.disabled = true;
        document.getElementById('theSpinner').style.display = 'block';
    }));
});

function disableCheckoutButton() {
    document.getElementById('checkoutButton').disabled = true;
    document.getElementById('checkoutButton').innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait...';
}
window.disableCheckoutButton = disableCheckoutButton;

function handleContactFormSubmit(){
    var form_output = '<!DOCTYPE html>\n<html>\n<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />\n</head>\n<body>\n<strong>Content from the user:</strong>:';
    form_output += '\n<p><strong>Name:</strong> ' + document.getElementById('contact_name').value + '</p>';
    form_output += '\n<p><strong>Email:</strong> ' + document.getElementById('contact_email').value + '</p>';
    form_output += '\n<p><strong>Phone:</strong> ' + document.getElementById('contact_phone').value + '</p>';
    form_output += '\n<p><strong>Message:</strong> ' + document.getElementById('contact_message').value + '</p>';
    form_output += '\n<p><strong>Contact Preference:</strong> ' + document.getElementById('contact_preference').value + '</p>';
    form_output += '\n</body>\n</html>\n'

    $.ajax({
        method: "POST",
        url: "/email_deliveries.js",
        data: { email_delivery: { mail_body: form_output } },
        success: function(data) { },
        error: function(data) {
            console.log("Error sending email");
            $('#contact_danger').removeClass('d-none');
        }
    })
}
window.handleContactFormSubmit = handleContactFormSubmit;

function onScrollAnimation() {
    const elements = document.querySelectorAll('.fade-in, .slide-in, .zoom-in');
    elements.forEach(el => {
        if (el.getBoundingClientRect().top < window.innerHeight) {
            el.classList.add('visible');
        }
    });
}

window.addEventListener('scroll', onScrollAnimation);
window.addEventListener('load', onScrollAnimation);