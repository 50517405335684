import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {

  static targets = [
    'billingFirstName',
    'billingLastName',
    'billingAddress1',
    'billingAddress2',
    'billingCity',
    'billingZipcode',
    'billingStateId',
    'billingCountryId',
    'shippingFirstName',
    'shippingLastName',
    'shippingAddress1',
    'shippingAddress2',
    'shippingCity',
    'shippingZipcode',
    'shippingStateId',
    'shippingCountryId',
    'cartTotalPrice',
    'cartShippingPrice',
    'billingStateSelector',
    'billingStateEntry',
    'shippingStateSelector',
    'shippingStateEntry'
  ]

  static values = {
    cartSubTotal: String
  }
  connect() {

  }

  async copyBillingToShipping(event) {
    this.shippingFirstNameTarget.value = this.billingFirstNameTarget.value
    this.shippingLastNameTarget.value = this.billingLastNameTarget.value
    this.shippingAddress1Target.value = this.billingAddress1Target.value
    this.shippingAddress2Target.value = this.billingAddress2Target.value
    this.shippingCityTarget.value = this.billingCityTarget.value
    this.shippingZipcodeTarget.value = this.billingZipcodeTarget.value
    this.shippingCountryIdTarget.value = this.billingCountryIdTarget.value

    await this.updateShippingState()

    this.shippingStateIdTarget.value = this.billingStateIdTarget.value
  }

  updateCartShipping({params: { price }}) {
    const cart_total = Number(Number(price).toFixed(2)) + Number(Number(this.cartSubTotalValue).toFixed(2))
    this.cartShippingPriceTarget.innerHTML = '$' + Number(price).toFixed(2)
    this.cartTotalPriceTarget.innerHTML = '$' + cart_total.toFixed(2)
  }

  async updateBillingState(event) {
    const country = this.billingCountryIdTarget.value;

    if (country === '1' || country === '2') {
      this.billingStateSelectorTarget.classList.remove('d-none');
      this.billingStateEntryTarget.classList.add('d-none');
    } else {
      this.billingStateSelectorTarget.classList.add('d-none');
      this.billingStateEntryTarget.classList.remove('d-none');
      return
    }

    const url = '/states?country=' + country
    const req = new FetchRequest("get", url, {
      contentType: "application/json",
      responseKind: "json"
    });
    const response = await req.perform();
    const data = await response.json;

    this.billingStateIdTarget.innerHTML = '';

    data.forEach(state => {
      const option = document.createElement('option');
      option.value = state.id;
      option.textContent = state.name;
      this.billingStateIdTarget.appendChild(option);
    });
  }

  async updateShippingState(event) {
    const country = this.shippingCountryIdTarget.value;

    if (country === '1' || country === '2') {
      this.shippingStateSelectorTarget.classList.remove('d-none');
      this.shippingStateEntryTarget.classList.add('d-none');
    } else {
      this.shippingStateSelectorTarget.classList.add('d-none');
      this.shippingStateEntryTarget.classList.remove('d-none');
      return
    }

    const url = '/states?country=' + country
    const req = new FetchRequest("get", url, {
      contentType: "application/json",
      responseKind: "json"
    });
    const response = await req.perform();
    const data = await response.json;

    this.shippingStateIdTarget.innerHTML = '';

    data.forEach(state => {
      const option = document.createElement('option');
      option.value = state.id;
      option.textContent = state.name;
      this.shippingStateIdTarget.appendChild(option);
    });
  }

}
